import { MessagingAPI } from '../axios'

//Get all messages by user
export const getMessages = async (memberId, requestedMemberId) => {
	return await MessagingAPI({
		url: `${memberId}/messages/${requestedMemberId}`,
		method: 'GET'
	})
}

//Update message status (read/unread) by user and messageId
export const postMessageStatus = async (memberId, messageId, body) => {
	return await MessagingAPI({
		url: `${memberId}/messages/${messageId}/status`,
		method: 'POST',
		body
	})
}

//Get message count by memberIds
export const getMessageCountByMemberId = async (memberId, requestedMemberIds) => {
	return await MessagingAPI({
		url: `${memberId}/messages/count?additionalRequestedMemberIds=${requestedMemberIds}`,
		method: 'GET'
	})
}

//Delete message by messageId
export const deleteMessageById = async (memberId, requestedMemberId) => {
	return await MessagingAPI({
		url: `${memberId}/messages/${requestedMemberId}`,
		method: 'DELETE'
	})
}

export const getTopicPreferences = async (memberId) => {
	return await MessagingAPI({
		url: `${memberId}/preferences`,
		method: 'GET'
	})
}

export const postTopicPreferences = async (memberId, body) => {
	return await MessagingAPI({
		url: `${memberId}/preferences`,
		method: 'POST',
		body
	})
}