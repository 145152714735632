<script setup>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import Five9ChatWidget from '@/components/Five9ChatWidget.vue'
import LogRocket from '@/components/LogRocket.vue'
import TimeoutModal from '@/components/TimeoutModal.vue'
import SystemMessageBanner from '@/components/SystemMessageBanner.vue'

const script = document.createElement('script')
script.src = encodeURI(import.meta.env.VITE_DYNATRACE_PATH)
script.crossOrigin = 'anonymous'
document.head.appendChild(script)
</script>

<template>
	<v-app>
		<LogRocket />
		<SystemMessageBanner />
		<NavBar v-if="!$route.meta.hideNavigation" />
		<RouterView class="h-100" />
		<ErrorModal />
		<LoadingSpinnerTakeover />
		<Footer v-if="!$route.meta.hideFooter" />
		<TimeoutModal />
		<Five9ChatWidget v-if="!$route.meta.hideChat" />
	</v-app>
</template>

<style lang="scss">
.link,
.link:visited,
.link:active {
	color: $text-brand;
	cursor: pointer;

	&:hover,
	&:focus {
		color: $neutral-900;
	}
}

.icon-btn {
	.v-btn__content {
		@media (max-width: 600px) {
			clip: rect(0 0 0 0);
			clip-path: inset(50%);
			height: 1px;
			overflow: hidden;
			position: absolute;
			white-space: nowrap;
			width: 1px;
		}
	}

	.v-btn__append {
		@media (max-width: 600px) {
			margin-inline-start: 0;
		}

		@media (min-width: 601px) {
			display: none;
		}
	}
}

form.inline-form {
	display: flex;
	align-items: stretch;

	.v-text-field {
		max-width: 100%;
		flex: 0 1 320px;

		.v-field {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.v-input__control {
		--v-input-control-height: 44px;
	}

	.v-btn {
		height: auto;

		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
</style>
